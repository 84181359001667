<template>
  <div>
    <a-card title="询价记录">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" allowClear @change="search" />
        </a-col>
        <a-col :md="8" :span="24" :xl="6" style="max-width: 200px; margin-bottom: 12px">
          <SupplierSelect v-model="searchForm.supplier" placeholder="供应商" @change="search" />
        </a-col>
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_closed" placeholder="截止状态" allowClear style="width: 100%" @change="search">
            <a-select-option :value="false">进行中</a-select-option>
            <a-select-option :value="true">已截止</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_quoted" placeholder="报价状态" allowClear style="width: 100%" @change="search">
            <a-select-option :value="true">已报价</a-select-option>
            <a-select-option :value="false">未报价</a-select-option>
          </a-select>
        </a-col>
        <a-col :md="8" :span="24" :xl="6" style="max-width: 200px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" allowClear placeholder="采购询价单号" @search="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          rowKey="id"
          size="small"
          @change="tableChange"
        >
          <div slot="purchase_inquiry_order_number" slot-scope="value, item">
            <a @click="detail(item)">{{ value }}</a>
          </div>
          <template slot="expandedRowRender" slot-scope="item">
            <a-table
              :columns="goodsColumns"
              :dataSource="item.purchase_quotation_goods_items"
              :pagination="false"
              rowKey="id"
              size="small"
            >
            </a-table>
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { purchaseInquiryTaskList } from "@/api/purchasing";

export default {
  components: {
    SupplierSelect: () => import("@/components/SupplierSelect"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "采购询价单号",
          dataIndex: "purchase_inquiry_order_number",
          scopedSlots: { customRender: "purchase_inquiry_order_number" },
        },
        {
          title: "供应商",
          dataIndex: "supplier_name",
        },
        {
          title: "截止时间",
          dataIndex: "closing_time",
        },
        {
          title: "报价状态",
          dataIndex: "is_quoted",
          customRender: (value) => (value ? "已报价" : "未报价"),
        },
        {
          title: "报价时间",
          dataIndex: "quotation_time",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
      ],

      goodsColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "物料单位",
          dataIndex: "goods_unit",
        },
        {
          title: "采购数量",
          dataIndex: "purchase_quantity",
        },
        {
          title: "采购单价",
          dataIndex: "purchase_price",
        },
        {
          title: "采购金额",
          dataIndex: "purchase_amount",
        },
      ],
      searchForm: { page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      searchDate: [undefined, undefined],
    };
  },
  computed: {},
  methods: {
    initData() {
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.loading = true;
      purchaseInquiryTaskList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    detail(item) {
      this.$router.push({ path: "/purchasing/purchase_inquiry_order_detail", query: { id: item.purchase_inquiry_order } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
